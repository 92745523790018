function submenuclickEvent(e) {
  const parent = e.target.closest(".menu-item");
  const siblings = Array.from(parent.parentNode.children);

  // Ignore close siblings if class submenu-toggler-noclose-siblings is set
  if (
    e.target.classList.contains("submenu-toggler-noclose-siblings") !== true
  ) {
    // Close other submenu siblings
    siblings.forEach(element => {
      if (element !== parent) {
        element.classList.remove("open");
      }
    });
  }
  // Open current
  parent.classList.toggle("open");
};

function openActiveSubmenu() {
  document
    .querySelectorAll(".submenu-toggler-noclose-siblings")
    .forEach(element => {
      if (
        element.parentNode.classList.contains("current-page-parent") === true
      ) {
        element.parentNode.classList.add("open");
      }
    });
};

function toggleArticleImages() {
  document.querySelectorAll("#kh-article div.duo-body img")
  .forEach(element => {
    element.addEventListener(
      "click",
      (e) => {
        e.target.parentNode.classList.toggle("active");
      });
  });
}

let navListLock = false;

function bind() {
  // Dropdown arrows
  document.querySelectorAll(".submenu-toggler").forEach(element => {
    element.addEventListener("click", submenuclickEvent, false);
  });

  // Menu burger icon
  document.querySelectorAll(".menu-toggler").forEach(element => {
    element.addEventListener(
      "click",
      () => {
        document.querySelector("#navigation-mobile").classList.toggle("open");
        document.querySelector("body").classList.toggle("mobile-overflow");
        // if (
        //   document
        //     .querySelector("#navigation-mobile")
        //     .classList.contains("open")
        // ) {
        //   document
        //     .querySelector("body")
        //     .setAttribute("style", "overflow:hidden;");
        // } else {
        //   document.querySelector("body").removeAttribute("style");
        // }
      },
      false
    );
  });

  // Responsive sidebar nav + Article responsive toc
  document.querySelectorAll(".navlist-toggler").forEach(element => {
    element.addEventListener(
      "click",
      e => {
        if (navListLock === true) {
          return;
        }
        navListLock = true;

        const time = 250;
        const navListWrapper = e.target.closest(".navlist-wrapper");
        const navListContainer = navListWrapper.querySelector(
          ".navlist-container"
        );
        const height = navListContainer.offsetHeight;

        if (navListWrapper.classList.contains("open")) {
          // navListWrapper.classList.toggle('open');
          navListContainer.setAttribute("style", `height: ${height}px`);
          setTimeout(() => {
            navListContainer.setAttribute("style", "height: 0px");
            // navListWrapper.classList.remove('open');
          }, 1);
          setTimeout(() => {
            navListWrapper.classList.remove("open");
            navListContainer.removeAttribute("style");
            navListLock = false;
          }, time);
        } else {
          navListWrapper.classList.add("open");
          navListContainer.setAttribute("style", "opacity: 0; height: 0px;");
          setTimeout(() => {
            navListContainer.setAttribute(
              "style",
              ` opacity: 1; height: ${height}px;`
            );
          }, 1);
          setTimeout(() => {
            navListContainer.setAttribute("style", "opacity: 1;");
            navListLock = false;
          }, time);
        }
        // e.target.closest('.navlist-wrapper').classList.toggle('open');
      },
      false
    );
  });

  

  openActiveSubmenu();
  toggleArticleImages();
};

export { bind };