



function getParam(key) {
  const array = window.location.search.slice(1).split('&');
  for (let index = 0; index < array.length; index += 1) {
    const element = array[index];
    const parts = element.split('=');
    if(key === parts[0]) {
      return parts[1];
    }
  }
  return false;
}

function removeReadmore(element) {
  if(element) {
    element.classList.remove("readmore-show");
  }
}

function enableReadmore() {
  document.querySelectorAll(".readmore-container").forEach(element => {
    element.classList.add('readmore-show');
  });
  document.querySelectorAll(".readmore-container.readmore-show .readmore-button").forEach(element => {
    element.addEventListener(
      "click",
      e => {
        const readmore = e.target.closest(".readmore-container");
        removeReadmore(readmore);
        window.location.hash = "readmore";
      },
      false
    );
  });
}


/* Kuuntelee jos ankkurilinkki muuttuu
 * yrittää poistaa mahdollisen readmore peitettävyyden 
 * ja varmistaa että ankkurilinkki tämän jälkeen skrollaa siihen kohtaan kun pitikin
 */
function setHashListener() {
  window.addEventListener("hashchange", () => {
    const {hash:h} = window.location;
    const readmoreElement = document.querySelector(".readmore-container");
    if(readmoreElement) {
      removeReadmore(readmoreElement);
    }
    const hashElement = document.querySelector(h);
    // Varmuuden vuoksi vielä skroll into view
    if( hashElement ) {
      hashElement.scrollIntoView();
    }
  }, false);
}

// Lainattun täältä
// https://codepen.io/santiagocab1/pen/pJNQVd

 function modalPrevNext($) {

  $("div[id^='khImageModal_']").each(function(){
  
  var currentModal = $(this);
  
  //click next
  currentModal.find('.btn-next').click(function(){
      currentModal.modal('hide');
      currentModal.closest("div[id^='khImageModal_']").nextAll("div[id^='khImageModal_']").first().modal('show'); 
  });
  
  //click prev
  currentModal.find('.btn-prev').click(function(){
      currentModal.modal('hide');
      currentModal.closest("div[id^='khImageModal_']").prevAll("div[id^='khImageModal_']").first().modal('show'); 
  });});

}




// TODO: maybe initKHArticle
function initKHArticle($) {

  // Ei tehdä tässä skriptissä mitään jos ei sivu ole kh-artikkeli
  const isarticle = document.querySelector('#kh-article');
  if(!isarticle) {
    return false;
  }

  // Modal kuvien navigointi
  modalPrevNext($);

  // Luetaan talteen mahdollinen ankurilinkki
  const {hash, pathname} = window.location;
  if(hash.length === 0 ) {
    // Asetetaan Lue lisää peittävyys vaan jos url:issa ei ole ankkurilinkkiä
    enableReadmore();
  }

  // Asetetaan ankkurilinkkikuuntelia
  setHashListener();

  // Avataan oikea välilehti tabissa get parametrin perusteella
  const tabParam = getParam('tab');
  if(tabParam) {
    $(`#${tabParam}-tab`).tab('show');
  }

  // Pakollinen Jquery / bootstrap temppu
  // Tabiklikkaus asettaa urliin get parametrin lataamatta sivua kuitenkaan uudelleen.
  $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
    const hashHref = $(e.target).attr('href').replace('#', '');
    history.replaceState({}, "-", `${pathname}?tab=${hashHref}${hash}`);
    // localStorage.setItem(articlePath, hashHref);
  });

}







export {initKHArticle};