
import "./polyfills";

import $ from 'jquery';
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/collapse";
// import "popper.js";
// import "bootstrap";
window.$ = $;

import {initKHArticle} from "./kharticle";
// import { init } from "./readmore";

import { bind } from "./menu";

$( document ).ready( () => {
  bind();
  initKHArticle($);
});
